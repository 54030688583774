<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <v-card :loading="loading" :disabled="loading">
          <v-card-text class="pt-5">
            <v-toolbar dense flat>
              <v-toolbar-title>
                  <v-text-field
                      v-model="search"
                      v-debounce:800ms.lock="launchSearch"
                      @click:clear="clearSearch"
                      :label="$store.getters.translate('search')"
                      single-line
                      hide-details
                      clearable
                      append-icon="mdi-magnify">
                  </v-text-field>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider/>
          </v-card-text>
          <v-card-text>
            <base-tags v-model="selected_tags" :modal_name="page.name"/>
          </v-card-text>
          <v-expansion-panels v-model="panel" multiple>
            <base-table-includable ref="projects_table" :page="page" relation_name="projects" @edit="openModel"/>
            <base-table-includable ref="assets_table" :page="page" relation_name="assets" @edit="openModel"/>
            <base-table-includable ref="documents_table" :page="page" relation_name="documents" @edit="openModel"/>
            <base-table-includable ref="notes_table" :page="page" relation_name="notes" @edit="openModel"/>
            <base-table-includable ref="companies_table" :page="page" relation_name="companies" @edit="openModel"/>
            <base-table-includable ref="contacts_table" :page="page" relation_name="contacts" @edit="openModel"/>
            <base-table-includable ref="forms_table" :page="page" relation_name="forms" @edit="openModel"/>
            <base-table-includable ref="employees_table" :page="page" relation_name="employees" @edit="openModel"/>
            <base-table-includable ref="equipments_table" :page="page" relation_name="equipment" @edit="openModel"/>
            <base-table-includable ref="fieldsets_table" :page="page" relation_name="fieldsets" @edit="openModel"/>
            <base-table-includable ref="reportsections_table" :page="page" relation_name="reportsections" @edit="openModel"/>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" ref="edit_note"/>
    <create-document modal_name="create_document" ref="create_document" @edit="goToAsset"/>
    <document-preview modal_name="document_preview" ref="document_preview" @edit="goToAsset" @create="createRevision"/>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import EditNote from "../notes/Edit";
import DocumentPreview from "../../components/commonComponents/DocumentPreview";
import CreateDocument from "../documents/Create";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseBreadcrumb,
    BaseTableIncludable,
    EditNote,
    CreateDocument,
    DocumentPreview,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("search"),
        name: "search",
        model: "search",
      },
      record: {},
      selected_tags: [],
      loading: false,
      search: "",
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.record = {};
    },
    launchSearch() {
      if(this.search && this.search.length > 2) {
        this.selected_tags = [];
        this.loading = true;
        for (let i = 0; i < helpFunctions.can_search.length; i++) {
          this.loadKeywordData(helpFunctions.can_search[i]);
        }
      }
    },
    async loadKeywordData(model) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/keywords", {
            model: model,
            search: this.search,
          }).then((response) => {
            this.loading = false;
            this.record[model] = response.data;
            this.updateIncludableTables();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    async loadTagData(model) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/tags", {
            model: model,
            tags: this.selected_tags,
          }).then((response) => {
            this.loading = false;
            this.record[model] = response.data;
            this.updateIncludableTables();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    updateIncludableTables() {
      if(this.canShow('project')) {
        this.$refs.projects_table.records = this.record.projects;
        this.$refs.projects_table.selected = [];
      }
      if(this.canShow('asset')) {
        this.$refs.assets_table.records = this.record.assets;
        this.$refs.assets_table.selected = [];
      }
      if(this.canShow('document')) {
        this.$refs.documents_table.records = this.record.documents;
        this.$refs.documents_table.selected = [];
      }
      if(this.canShow('note')) {
        this.$refs.notes_table.records = this.record.notes;
        this.$refs.notes_table.selected = [];
      }
      if(this.canShow('company')) {
        this.$refs.companies_table.records = this.record.companies;
        this.$refs.companies_table.selected = [];
      }
      if(this.canShow('contact')) {
        this.$refs.contacts_table.records = this.record.contacts;
        this.$refs.contacts_table.selected = [];
      }
      if(this.canShow('form')) {
        this.$refs.forms_table.records = this.record.forms;
        this.$refs.forms_table.selected = [];
      }
      if(this.canShow('employee')) {
        this.$refs.employees_table.records = this.record.employees;
        this.$refs.employees_table.selected = [];
      }
      if(this.canShow('equipment')) {
        this.$refs.equipments_table.records = this.record.equipment;
        this.$refs.equipments_table.selected = [];
      }
      if(this.canShow('fieldset')) {
        this.$refs.fieldsets_table.records = this.record.fieldsets;
        this.$refs.fieldsets_table.selected = [];
      }
      if(this.canShow('reportsection')) {
        this.$refs.reportsections_table.records = this.record.reportsections;
        this.$refs.reportsections_table.selected = [];
      }
    },
    openModel(item, model) {
      if(model === 'asset') {
        this.$router.push({name: "Platforms", params: { asset_id: item.id }});
      }
      else if(model === 'company') {
        this.$router.push("/companies/" + item.id);
      }
      else if(model === 'equipment') {
        this.$router.push("/equipment/" + item.id);
      }
      else if(model === 'note') {
        this.$refs.edit_note.load(item.id);
      }
      else if(model === 'document') {
        this.$refs.document_preview.load(item.id);
      }
      else {
        this.$router.push("/" + model + "s/" + item.id);
      }
    },
    goToAsset(asset_id) {
      this.$router.push({name: "Platforms", params: { asset_id: asset_id }});
    },
    createRevision(document) {
      this.$refs.create_document.load(document, null, null);
    },
    canShow(model) {
      return this.$can("show", this.$createEntity(model, {id: 0}));
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
  watch: {
    selected_tags: {
      handler() {
        if(this.selected_tags.length > 0) {
          this.search = "";
          this.loading = true;
          for (let i = 0; i < helpFunctions.can_search.length; i++) {
            this.loadTagData(helpFunctions.can_search[i]);
          }
        }
        else {
          this.record = {};
        }
      },
    },
  },
};
</script>
